import React, { useEffect, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import AOS from "aos";
import 'aos/dist/aos.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";

const loading = (
  <div className='pt-3 text-center'>
    <div className='sk-spinner sk-spinner-pulse'></div>
  </div>
);

const LandingPage = React.lazy(() => import("./views/landing_page/HomePage"));
const AboutUsPage = React.lazy(() => import("./views/about_us/AboutPage"));
const ClinicsPage = React.lazy(() => import("./views/clinics_page/Clinics"));
const DoctorsPage = React.lazy(() => import("./views/doctors_page/Doctors"));
const PetParentsPage = React.lazy(() => import("./views/pet_parents_page/PetParents"));
const FeaturesPage =React.lazy(()=> import("./views/features_page/FeaturesPage"));
const BlogsPage =React.lazy(()=> import("./views/blogs_page/BlogsPage"));
const TermsCondition = React.lazy(() => import("./views/terms_policy/TermsCondition"));
const PrivacyPolicy = React.lazy(() =>import("./views/terms_policy/privacyPolicy"));
const ContactUsPage = React.lazy(() =>import("./views/contact_us/ContactPage"));
const PidiquPage =React.lazy(()=> import ("./views/about_pidiqu/AboutPidiquPage"))
const FAQPage = React.lazy(() =>import("./views/faq/FAQ"));
function App() {
  useEffect(() => {
    AOS.init(); //This is the animation initial load for the landing pages

    document.body.classList.add("vet-app");
    return () => {
      document.body.classList.remove("vet-app");
    };
  }, []);
  return (
    <Suspense fallback={loading}>
      <Router basename="/">
        <Routes>
          <Route exact path="/" name="Landing page" element={<LandingPage />} />
          <Route
          exact
          path="/aboutus"
          name="aboutus page"
          element={<AboutUsPage />}
        />
        <Route
          exact
          path="/clinics"
          name="clinics page"
          element={<ClinicsPage />}
        />
         {/* <Route
          exact
          path="/doctors"
          name="doctors page"
          element={<DoctorsPage />}
        /> */}
         <Route
          exact
          path="/pet_parents"
          name="petParents page"
          element={<PetParentsPage />}
        />
         <Route
          exact
          path="/features"
          name="features page"
          element={<FeaturesPage />}
        />
         <Route
          exact
          path="/blogs"
          name="blogs page"
          element={<BlogsPage />}
        />
         <Route
          exact
          path="/faq"
          name="faq page"
          element={<FAQPage />}
        />
         <Route
          exact
          path="/contactus"
          name="contactus page"
          element={<ContactUsPage />}
        />
         <Route
          exact
          path="/pidiqu"
          name="pidiqu page"
          element={<PidiquPage />}
        />
          <Route
            exact
            path="/terms"
            name="terms page"
            element={<TermsCondition />}
          />
          <Route
            exact
            path="/policy"
            name="policy  page"
            element={<PrivacyPolicy />}
          />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
